// @ts-nocheck
// ignore whole file for coverage tslint:disable
'use server';
import { getForm } from '@/lib/hubspot/services/formService';
import { getFooterData } from '@/sanity/services/footerService';
import FooterWrapper from './footer/FooterWrapper';

export default async function Footer({ simpleMode }: { simpleMode?: boolean }) {
  const data = await getFooterData();
  const formId = data?.footer?.form?.formId;
  return (
    <>
      <FooterWrapper data={data} simpleMode={simpleMode} formId={formId} />
    </>
  );
}
